import { maxWidthQueries } from '@utils/useMediaQuery'
import { useEmblaCarousel } from 'embla-carousel/react'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

const Embla = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ height }) => height && `height: ${height}`};
`
const EmblaContainer = styled.div`
  display: flex;
`
export const EmblaSlide = styled.div`
  position: relative;
  flex: 0 0 100%;
  margin-right: 10px;
  overflow: hidden;

  img {
    max-width: initial;
  }
`
const EmblaDots = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  flex: 0 0 100%;
`
const EmblaDot = styled.button`
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  height: 20px;
  width: 20px;
  border: none;
  margin-right: 5px;
  transition: all ease-in 0.2s;
  ${({ selected, theme }) =>
    selected ? `background: ${theme.colors.teal}` : `background: ${theme.colors.grey}`};
  &:last-child {
    margin-right: 0;
  }
`

const SentiusHolder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  transform: translateX(2vw);
  @media ${maxWidthQueries.sm} {
    transform: translateX(0);
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
`

export const DotButton = ({ selected, onClick }) => (
  <EmblaDot selected={selected} type="button" onClick={onClick} />
)

const Slider = ({ slides, children, padding, margin, height }) => {
  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false })
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <SentiusHolder>
      <Embla height={height} margin={margin} padding={padding}>
        {children}
        <div ref={viewportRef}>
          <EmblaContainer>{slides.map(slide => slide)}</EmblaContainer>
        </div>
        {slides.length > 1 && (
          <EmblaDots>
            {scrollSnaps.map((_, index) => (
              <DotButton
                // eslint-disable-next-line react/no-array-index-key
                key={`dot_${index}`}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </EmblaDots>
        )}
      </Embla>
    </SentiusHolder>
  )
}

export default Slider
